<template>
  <div class="container-fluid m-0">
    <div class="row">
      <div v-for="(qrCodeId, index) in [0,1,3,4]" :key="index+'-qr'" class="col-6">
        <div :id="'qr-code-'+qrCodeId" class="text-center">
          <p class="typography-headline-md fw-bold text-uppercase text-body mt-3 mb-0">
            {{ name }}
          </p>

          <div class="mt-3 mb-3" v-if="restaurantURL">
            <QrcodeVue :value="restaurantURL"
                       :size="300"
                       render-as="svg"/>
          </div>
          <div v-else>
            Generating QR code...
          </div>


          <p class="paragraph fw-500 text-uppercase text-body mb-0">Scan this QR code for the restaurant menu</p>
          <p v-if="restaurantURL" class="subtitle-1 text-muted">Or visit <span class="fw-500">
            {{ restaurantURL }}</span></p>

        </div>
      </div>
    </div>
  </div>
</template>
<script>
import QrcodeVue from "qrcode.vue";

export default {
  components: {QrcodeVue},
  mounted() {
    setTimeout(this.printPage, 2000)
  },
  data() {
    return {
      name: this.$route.query.name,
      restaurantURL: this.$route.query.url,
    }
  },
  methods: {
    printPage() {
      window.print()
    }
  }
}
</script>
